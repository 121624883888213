import PropTypes from "prop-types"
import React, {useState} from "react"
import {
    Container,
    AppBar,
    Typography,
    Drawer,
    ListItemIcon,
    ListItem,
    Divider,
    ListItemText,
    List,
    Box,
    ListSubheader,
    Toolbar,
    Grid
    
} from "@material-ui/core";
import * as headerStyles from "./header.module.scss"

import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import PostAddIcon from '@material-ui/icons/PostAdd';
import InfoIcon from '@material-ui/icons/Info';
import { useIntl, navigate} from "gatsby-plugin-intl"
import Language from "./lenguajes";



const Header = ({siteTitle}) => {
    const intl = useIntl();
    const [anchor, setAnchor] = useState("left")
    const [state, setState] = useState({
        left: false
    });
    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        
        setState({...state, [anchor]: open});
    };
    
    
    const list = (anchor) => (
        <div
            className={"list"}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem button onClick={() => {
                    navigate("/")
                }}>
                    <ListItemIcon> <HomeIcon/></ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "home"})} />
                </ListItem>
                <Divider/>
                <ListSubheader>{intl.formatMessage({id: "titulo"})}</ListSubheader>
                <ListItem button  onClick={() => {
                    window.open("https://serviciosenlinea.sinac.go.cr/", "_blank")
                }}>
                    <ListItemIcon><PostAddIcon/></ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SsP"})}/>
                </ListItem>

                <ListItem button onClick={() => {
                    navigate("/santa-rosa")
                }}>
                    <ListItemIcon><PostAddIcon/></ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SSR"})} />
                </ListItem>
                <ListItem button  onClick={() => {
                    navigate("/naranjo")
                }}>
                    <ListItemIcon><PostAddIcon/></ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SPN"})}/>
                </ListItem>

                <ListItem button onClick={() => {
                    window.open("https://serviciosenlinea.sinac.go.cr/", "_blank")
                }}>
                    <ListItemIcon><PostAddIcon/></ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SStMa"})} />
                </ListItem>
                
                <ListItem button onClick={() => {
                    navigate("/marino")
                }}>
                    <ListItemIcon><PostAddIcon/></ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SsM"})} />
                </ListItem>
               
                <ListItem button>
                    <ListItemIcon><PostAddIcon/></ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SH"})} onClick={() => {
                        navigate("/horizontes")
                    }}/>
                </ListItem>
                <ListItem button>
                    <ListItemIcon><PostAddIcon/></ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SMu"})} onClick={() => {
                        navigate("/murcielago")
                    }}/>
                </ListItem>
                <ListItem button>
                    <ListItemIcon><PostAddIcon/></ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SREsc2"})} onClick={() => {
                        navigate("/sr-escuelas")
                    }}/>
                </ListItem>
                <ListItem button>
                    <ListItemIcon><PostAddIcon/></ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SsJ"})} onClick={() => {
                        navigate("/junquillal")
                    }}/>
                </ListItem>
                
            </List>
            <Divider/>
            
            <ListSubheader>{intl.formatMessage({id: "inft"})}</ListSubheader>

            <List>
                <ListItem button  onClick={() => {
                    window.location.href = 'https://www.acguanacaste.ac.cr/turismo/sector-pailas'
                }}>
                    <ListItemIcon><InfoIcon/> </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SsP"})}/>
                </ListItem>
                <ListItem button>
                    <ListItemIcon><InfoIcon/> </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SH"})} onClick={() => {
                        window.location.href = 'https://www.acguanacaste.ac.cr/turismo/sector-horizontes'
                    }}/>
                </ListItem>
                <ListItem button  onClick={() => {
                    window.location.href = 'https://www.acguanacaste.ac.cr/turismo/sector-santa-rosa/sector-santa-rosa'
                }}>
                    <ListItemIcon><InfoIcon/> </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SSR"})}/>
                </ListItem>
                <ListItem button  onClick={() => {
                    window.location.href = 'https://www.acguanacaste.ac.cr/turismo/sector-santa-rosa/playa-naranjo-sector-santa-rosa'
                }}>
                    <ListItemIcon><InfoIcon/> </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SPN"})}/>
                </ListItem>

                {<ListItem button onClick={() => {
                    window.location.href = 'https://www.acguanacaste.ac.cr/biodesarrollo/sector-marino'
                }}>
                    <ListItemIcon><InfoIcon/> </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SsM"})} />
                </ListItem>}

                <ListItem button  onClick={() => {
                    window.location.href = 'https://www.acguanacaste.ac.cr/turismo/sector-santa-maria'
                }}>
                    <ListItemIcon><InfoIcon/> </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SStMa"})}/>
                </ListItem>

                <ListItem button  onClick={() => {
                    window.location.href = 'https://www.acguanacaste.ac.cr/turismo/sector-junquillal'
                }}>
                    <ListItemIcon><InfoIcon/> </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SsJ"})}/>
                </ListItem>

                <ListItem button  onClick={() => {
                    window.location.href = 'https://www.acguanacaste.ac.cr/turismo/sector-murcielago'
                }}>
                    <ListItemIcon><InfoIcon/> </ListItemIcon>
                    <ListItemText primary={intl.formatMessage({id: "SMu"})}/>
                </ListItem>
                
            </List>
        </div>
    );
    
    
    return (
        <header className={headerStyles.header}>
            <AppBar position="static" >
                <Container maxWidth="md">
                    <Toolbar>
                        <Grid container alignItems="center">
                            <Grid item xs={12}  sm={9} md={8} direction="row" alignItems="center" container>
                                <div className={headerStyles.inline}>
                                    <React.Fragment key={anchor}>
                                        <MenuIcon fontSize={"large"}
                                                  onClick={toggleDrawer(anchor, true)}>Menú</MenuIcon>
                                        <Drawer anchor={anchor} open={state[anchor]}
                                                onClose={toggleDrawer(anchor, false)}>
                                            {list(anchor)}
                                        </Drawer>
                                    </React.Fragment>
                                </div>
                                <div className={headerStyles.inline}>
                                    <Typography variant="h6" component="h1" style={{margin: 0, paddingBottom: "2px"}} gutterBottom
                                                className={siteTitle} onClick={toggleDrawer(anchor, true)}>
                                        {intl.formatMessage({id: "titulo"})}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item sm={3} md={3}>
                                <Language  />
                            </Grid>
                            <Box component={Grid} item md={1} display={{ xs: "none", md: "block" }}>
                                <a target={"_blank"} href={"https://www.acguanacaste.ac.cr/"} >
                                    <img style={{maxWidth: "3rem"}}
                                         src={'/images/ACG-logo.png'}
                                         alt="Logo Área de Conservación Guanacaste, Fuente de Vida y Desarollo"/>
                                </a>
                            </Box>
                        </Grid>
                        
                        
                        
                        
                        
                        
                        
                        
                        
                    </Toolbar>
                </Container>
            </AppBar>
        </header>
       
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
