import React from "react"
import { IntlContextConsumer, changeLocale, useIntl } from "gatsby-plugin-intl"
import Flag from 'react-world-flags'
import {Typography, Box} from "@material-ui/core";

const languageName = {
    es: {label: "Español", flag:"es"},
    en: {label: "English", flag:"us"},
    fr: {label: "Francais", flag:"fr"},
    de: {label: "Deutsche", flag:"de"}
}

const Language = () => {
    const intl = useIntl();
    return (
        <Box display="flex" direction={"row"} >
            <Typography component="span" variant={"body2"}> {intl.formatMessage({id: "idioma"})}:</Typography>
            <IntlContextConsumer>
                
                {({ languages, language: currentLocale }) =>
                    languages.map(language => (
                        <a
                            key={language}
                            onClick={() => changeLocale(language)}
                            style={{
                                color: currentLocale === language ? `yellow` : `white`,
                                margin: 5,
                                textDecoration: `underline`,
                                cursor: `pointer`,
                            }}
                        ><Flag code={languageName[language].flag} height="16" /></a>
                    ))
                }
            </IntlContextConsumer>
        </Box>
    )
}

export default Language
